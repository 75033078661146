<template>
    <footer class="pb-4">
        <ul class="nav">
            <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'dashboard' }">Hexeko Admin</router-link>
            </li>
            <li class="nav-item">
                <a href="https://data.hexeko.com" target="_blank" class="nav-link">
                    <Icon icon="external-link-alt" /> Data
                </a>
            </li>
            <li class="nav-item">
                <div class="nav-link disabled">Logged in as {{ identity }}</div>
            </li>
            <li class="nav-item">
                <a href="#" @click.prevent="logout" class="nav-link">Logout</a>
            </li>
        </ul>
    </footer>
</template>

<script>
    import { signoutRedirect, userManager } from '../../../oidc.js'

    export default {
        name: 'Footer',
        computed: {
            identity() {
                let identity = ''
                identity = this.$store.state.admin.user.name ? this.$store.state.admin.user.name : 'N/D'
                if (this.$store.state.admin.user.email) identity += ` (${this.$store.state.admin.user.email})`
                return identity
            },
        },
        methods: {
            async logout() {
                try {
                    await signoutRedirect()
                } catch (error) {
                    console.error('Signout error: ', error)
                }
            },
        },
    }
</script>
