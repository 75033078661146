<template>
    <Admin v-if="$store.state.admin.user" class="text-xs" />
    <LoaderFullscreen v-else />
</template>

<script>
    import { getSessionOrRefresh, signoutRedirect, userManager } from '../oidc.js'
    import { LoaderFullscreen } from '@tech_hexeko/design-system'
    import Admin from './Admin'

    export default {
        name: 'App',
        components: {
            Admin,
            LoaderFullscreen,
        },
        methods: {
            async handleCallback() {
                // Signin callback
                userManager
                    .signinCallback()
                    .then(async (authData) => {
                        await this.processAuth(authData)
                    })
                    .catch(() => {
                        this.signIn()
                    })
            },
            async handleStorageUpdate(e) {
                // Warn other tabs to signout simultaneously
                if (e.key === 'hexeko:loggedOut' && e.newValue === 'true') await signoutRedirect()
            },
            async processAuth(data) {
                if (data && data.access_token) {
                    // Fetch User
                    const profile = await this.$api.me.showProfile()
                    this.$store.commit('SET_USER', profile)
                } else {
                    // Unauthenticated
                    this.signIn()
                }
            },
            async signIn() {
                await userManager.signinRedirect()
            },
        },
        async created() {
            window.addEventListener('storage', this.handleStorageUpdate)
            if (this.$route.query.code) return this.handleCallback()
            try {
                // Try to restore session
                const user = await getSessionOrRefresh()
                this.processAuth(user)
            } catch {
                this.handleCallback()
            }
        },
        beforeDestroy() {
            window.removeEventListener('storage', this.handleStorageUpdate)
        },
    }
</script>
