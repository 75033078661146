import Vue from 'vue';

// Import SCSS
import './sass/app.scss';

// Hexeko Base Front
import { store, i18n } from '@tech_hexeko/base-front';

// Add design system translations
import messages from '@tech_hexeko/design-system/src/lang';
store.dispatch('lang/addTranslations', messages);

// Add specific UI translations
import messagesUI from './lang/ui';
store.dispatch('lang/addTranslations', messagesUI);

// Mount App
import App from './components/App';
Vue.config.productionTip = false;

// VueRouter
import VueRouter from 'vue-router';
import routes from './routes';
const router = new VueRouter({
    mode: 'history',
    routes: routes,
});

// Hexeko Admin Config
import config from './config';
Vue.use(config, {
    router: router,
});

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
