<template>
    <Panel :title="group && group.name ? group.name : '…'" :loading="loading" @closePanel="closePanel">
        <Tabs v-if="group" ref="groupTabs" wrap>
            <!-- Group -->
            <Tab name="Group" :selected="true">
                <Tabs ref="infoTabs" :pills="false" small>
                    <Tab name="Group" :selected="true">
                        <div class="mb-2">
                            <Boolean
                                v-model="group.cards_allowed"
                                textOn="Card issuing enabled"
                                textOff="Card issuing disabled"
                                class="me-1"
                            ></Boolean>
                            <Boolean
                                :value="haveBillingAccounts"
                                textOn="Has billing account"
                                textOff="No billing account"
                                class="me-1"
                            ></Boolean>
                            <GroupSource :group="group" suffix="source" />
                        </div>
                        <Sheet class="mb-2">
                            <Row name="Name">{{ group.name }}</Row>
                            <template v-if="group.contacts">
                                <Row name="E-mail" v-if="group.contacts.email"
                                    ><a :href="'mailto:' + group.contacts.email">{{ group.contacts.email }}</a></Row
                                >
                                <Row name="Phone" v-if="group.contacts.phone"
                                    ><a :href="'tel:' + group.contacts.phone">{{ group.contacts.phone }}</a></Row
                                >
                            </template>
                            <Row name="Address" v-if="group.address">
                                <Address :address="group.address" class="mb-1" />
                                <div>
                                    <a :href="mapLink" target="_blank"><Icon icon="map-marker-alt" /> Map</a>
                                </div>
                            </Row>
                            <Row
                                v-if="group.representative && group.representative.id && group.representative.name"
                                name="Representative"
                            >
                                <a href="#" @click.prevent="openUser(group.representative)"
                                    ><Icon icon="user" /> {{ group.representative.name }}</a
                                >
                            </Row>
                            <Row name="Market segment" v-if="group.market_segment && group.market_segment.name">{{
                                group.market_segment.name
                            }}</Row>
                            <Row name="VAT number" v-if="group.vat_number">{{ group.vat_number }}</Row>
                            <Row name="Registration number" v-if="group.registration_number">{{
                                group.registration_number
                            }}</Row>
                            <Row name="Registration country" v-if="group.registration_country">{{
                                group.registration_country
                            }}</Row>
                            <Row name="IBAN">
                                <span v-if="group.iban">{{ group.iban }}</span
                                ><span v-else>Unknown</span>
                            </Row>
                            <Row name="Stores">
                                <Store
                                    :id="store.id"
                                    :key="index"
                                    v-for="(store, index) in group.access_stores"
                                    class="me-1"
                                ></Store>
                            </Row>
                            <Row name="Created at">{{ group.created_at | formatDate }}</Row>
                            <Row name="Updated at">{{ group.updated_at | formatDate }}</Row>
                            <Row name="Timezone">{{ group.timezone || '-' }}</Row>
                        </Sheet>
                    </Tab>
                    <!-- Root -->
                    <Tab name="Root" v-if="group.root_name">
                        <Sheet>
                            <Row name="Last name" v-if="group.root_name">{{ group.root_name }}</Row>
                            <Row name="First name" v-if="group.root_surname">{{ group.root_surname }}</Row>
                            <Row name="Company position" v-if="group.root_company_position">{{
                                group.root_company_position
                            }}</Row>
                            <Row name="E-mail" v-if="group.root_email">{{ group.root_email }}</Row>
                            <Row name="Mobile" v-if="group.root_mobile">{{ group.root_mobile }}</Row>
                            <Row name="Birthdate" v-if="group.root_birthday">{{
                                group.root_birthday | formatDate('DD/MM/YYYY')
                            }}</Row>
                            <Row name="Address" v-if="group.root_address">
                                <Address :address="group.root_address" />
                            </Row>
                        </Sheet>
                    </Tab>

                    <!-- Documents -->
                    <Tab name="Documents">
                        <Documents :groupId="id" />
                    </Tab>
                </Tabs>
            </Tab>

            <!-- Users -->
            <Tab name="Users" v-if="$store.getters.userHasAnyPermission(['showUsers', 'manageUsers'])">
                <Users :params="{ group: group.id }" @userSubmitted="addUserToGroup"></Users>
            </Tab>

            <!-- Cards -->
            <Tab name="Cards" v-if="$store.getters.userHasAnyPermission(['showCards', 'manageCards'])">
                <Tabs :pills="false" :small="true">
                    <!-- Balance-->
                    <Tab name="Issuing" :selected="true">
                        <Sheet class="mb-4">
                            <Row name="Can issue cards now"><Boolean v-model="group.issuing.can_issue" /></Row>
                            <Row name="Cards brand" v-if="group.cards_brand">
                                <Brand :slug="group.cards_brand" />
                            </Row>
                            <Row name="Funding system" v-if="group.issuing.funding_system">
                                {{ group.issuing.funding_system }}
                            </Row>
                            <Row name="IBAN for individual deposit">
                                <span
                                    v-if="group.issuing.funding_instructions && group.issuing.funding_instructions.iban"
                                    >{{ group.issuing.funding_instructions.iban }}</span
                                >
                                <span v-else>Unknown</span>
                            </Row>
                            <Row name="BIC for individual deposit">
                                <span
                                    v-if="group.issuing.funding_instructions && group.issuing.funding_instructions.bic"
                                    >{{ group.issuing.funding_instructions.bic }}</span
                                >
                                <span v-else>Unknown</span>
                            </Row>
                        </Sheet>
                        <Sheet name="Issuing Balance" class="my-2">
                            <Row name="Current Issuing Balance">
                                <span v-if="group.issuing.balance !== null">{{
                                    group.issuing.balance | currency
                                }}</span>
                                <span v-else>Unknown</span>
                            </Row>
                            <Row name="Should be (estimated)">
                                {{ group.issuing.balance_estimated | currency
                                }}<span class="text-muted">
                                    +
                                    {{ group.issuing.balance_alt_groups | currency }}
                                    (other groups) + contribution<sup>1</sup></span
                                >
                            </Row>
                            <Row name="Needs to fund (difference)" v-if="group.issuing.balance_difference !== null">
                                <div
                                    :class="{
                                        'text-success': group.issuing.balance_difference > 0,
                                        'text-danger': group.issuing.balance_difference < 0,
                                    }"
                                >
                                    <span
                                        >{{ Math.abs(group.issuing.balance_difference) | currency }}
                                        should be
                                        <span v-if="group.issuing.balance_difference > 0">withdrawed from</span
                                        ><span v-else>deposited on</span> Issuing Balance</span
                                    >
                                </div>
                            </Row>
                            <Row name="Total deposited" v-if="group.issuing.balance_total_deposited !== null">
                                <span>{{ group.issuing.balance_total_deposited | currency }}</span>
                            </Row>
                        </Sheet>
                        <Sheet v-if="group.issuing.credit_policy" name="Issuing Credit Policy" class="my-2">
                            <Row name="Status" v-if="group.issuing.credit_policy.status">
                                <CreditPolicyStatus :status="group.issuing.credit_policy.status" />
                            </Row>
                            <Row name="Credit Limit" v-if="group.issuing.credit_policy.credit_limit_amount">
                                {{ group.issuing.credit_policy.credit_limit_amount }}
                            </Row>
                            <Row name="Currency" v-if="group.issuing.credit_policy.credit_limit_currency">
                                <span class="text-uppercase">{{
                                    group.issuing.credit_policy.credit_limit_currency
                                }}</span>
                            </Row>
                            <Row
                                name="Billing Interval"
                                v-if="
                                    group.issuing.credit_policy.credit_period_interval_count &&
                                    group.issuing.credit_policy.credit_period_interval
                                "
                            >
                                Every {{ group.issuing.credit_policy.credit_period_interval_count }}
                                {{ group.issuing.credit_policy.credit_period_interval }}
                            </Row>
                            <Row name="Days until due">
                                {{ group.issuing.credit_policy.days_until_due }}
                            </Row>
                            <Row name="Quote References" v-if="formattedCreditPolicyQuoteRefs.length > 0">
                                {{ formattedCreditPolicyQuoteRefs.join(', ') }}
                            </Row>
                            <Row
                                name="Last update"
                                v-if="group.issuing.credit_policy.last_effective_attributes.effective_until"
                            >
                                {{
                                    group.issuing.credit_policy.last_effective_attributes.effective_until
                                        | formatDate('DD/MM/YYYY')
                                }}
                            </Row>
                        </Sheet>
                        <p class="mt-1 text-muted text-xxs">
                            <sup>1</sup> For companies with fewer employees, we may add a certain contribution amount.
                        </p>
                    </Tab>

                    <!-- Card -->
                    <Tab name="Cards">
                        <Cards :params="{ group: group.id }" hideActions></Cards>
                    </Tab>

                    <!-- Card Authorizations-->
                    <Tab name="Authorizations">
                        <CardAuthorizations :params="{ groupId: group.id }" />
                    </Tab>

                    <!-- Card Transactions-->
                    <Tab name="Transactions">
                        <CardTransactions :params="{ groupId: group.id }" />
                    </Tab>
                </Tabs>
            </Tab>

            <!-- Wallets -->
            <Tab name="Wallets" v-if="$store.getters.userHasAnyPermission(['showWallets', 'manageWallets'])">
                <Tabs :pills="false" :small="true">
                    <!-- Wallets-->
                    <Tab name="Wallets" :selected="true">
                        <Wallets :params="{ source_type: 'group', source_id: group.id }" />
                    </Tab>
                    <!-- Types -->
                    <Tab name="Available types">
                        <Table :loading="loading" striped hover class="mb-0">
                            <template v-for="type in group.available_types">
                                <TypeRow :type="type" @click="openType(type)" />
                            </template>
                        </Table>
                    </Tab>
                </Tabs>
            </Tab>

            <Tab name="Expenses" v-if="$store.getters.userHasAnyPermission(['showExpenses', 'manageExpenses'])">
                <Tabs ref="expensesTabs" :pills="false" small>
                    <Tab name="Expense Requests" :selected="true">
                        <ExpenseRequests :params="{ groupId: group.id }" />
                    </Tab>
                </Tabs>
            </Tab>

            <!-- Bookings -->
            <Tab name="Bookings" v-if="$store.getters.userHasAnyPermission(['showBookings', 'manageBookings'])">
                <Bookings :params="{ group: group.id }" hideActions></Bookings>
            </Tab>

            <!-- Licenses -->
            <Tab name="Licenses" v-if="$store.getters.userHasAnyPermission(['showLicenses', 'manageLicenses'])">
                <Licenses :group="group.id" hideActions ref="licenses" />
            </Tab>

            <!-- Payouts -->
            <Tab name="Payouts" v-if="$store.getters.userHasAnyPermission(['showPayouts', 'managePayouts'])">
                <PayoutRequests :params="{ source_type: 'group', source_id: group.id }" />
            </Tab>

            <Tab name="Billing">
                <Datatable
                    name="Billing Accounts"
                    @search="fetchBillingAccounts"
                    :hover="false"
                    :pagination.sync="pagination"
                    :loading="loadingBillingAccounts"
                    class="mt-4"
                >
                    <template v-slot:thead>
                        <tr>
                            <th>Name</th>
                            <th>E-mail</th>
                            <th>City</th>
                        </tr>
                    </template>
                    <tr v-for="(billingAccount, index) in billingAccounts" :key="'billingAccount-' + index">
                        <td>
                            <a href="#" @click.prevent="openBillingAccount(billingAccount.id)">{{
                                billingAccount.name
                            }}</a>
                        </td>
                        <td>{{ billingAccount.email }}</td>
                        <td>
                            {{ billingAccount.city }}
                            {{ billingAccount.country_iso }}
                        </td>
                        <td align="right">
                            <a
                                href="#"
                                is="confirm"
                                tag="a"
                                @confirm="detachBillingAccount(billingAccount.id)"
                                title="Detach billing account"
                                class="text-danger"
                                ><Icon icon="times" /> Detach billing account</a
                            >
                        </td>
                    </tr>
                </Datatable>
            </Tab>

            <!-- Rules -->
            <Tab name="Rules">
                <Rules :group="group.id" hideActions />
            </Tab>

            <!-- Administrators -->
            <Tab name="Administrators" v-if="$store.getters.userHasPermission('manageGroups')">
                <Administrators :group="group" />
            </Tab>
        </Tabs>

        <!-- Footer -->
        <div v-if="group" class="mt-4">
            <ID name="Hexeko" :value="group.id" class="me-1" />
            <ID
                v-for="(id, app) in groupExternalID"
                :key="`external-id-${app}`"
                class="me-1"
                :name="app"
                :value="id"
                :url="parseExternalUrl(app, id)"
            />
        </div>

        <template v-slot:actions v-if="group && $store.getters.userHasPermission('manageGroups')">
            <!-- Actions -->
            <Dropdown small title="Actions" position="right">
                <DropdownItem @click="openModal('updateGroup')" icon="users-cog">Update group</DropdownItem>
                <DropdownItem @click="openModal('updateResponsible')" icon="user-tie">Update responsible</DropdownItem>
                <DropdownItem @click="openModal('createUser')" icon="user-plus">Add user</DropdownItem>
                <DropdownItem
                    @click="handleExportUsers"
                    icon="file-download"
                    :class="{ 'pe-none opacity-50': loadingExportUsers }"
                    >Export users</DropdownItem
                >
                <DropdownItem @click="openModal('attachBillingAccount')" icon="building"
                    >Attach billing account</DropdownItem
                >
                <DropdownItem
                    @click="openModal('createLicense')"
                    :disabled="!haveBillingAccounts"
                    icon="certificate"
                    v-if="$store.getters.userHasPermission('manageLicenses')"
                >
                    Create license
                </DropdownItem>
                <DropdownItem
                    v-if="
                        $store.getters.userHasPermission('manageWallets') &&
                        $store.getters.userHasPermission('manageGroups')
                    "
                    @click="openModal('extendWalletTransactions')"
                    icon="wallet"
                >
                    Extend wallets transactions
                </DropdownItem>
                <DropdownItem
                    @click="allowCards()"
                    :icon="loadingAllowCard ? '' : 'credit-card'"
                    :disabled="loadingAllowCard"
                    v-if="!group.cards_allowed"
                >
                    <template v-if="loadingAllowCard">
                        <Spinner size="1x" />
                        <span class="ms-1">In progress…</span>
                    </template>
                    <template v-else>
                        <span>Allow card issuing</span>
                    </template>
                </DropdownItem>
                <DropdownItem
                    @click="refuseCards()"
                    :icon="loadingRefuseCard ? '' : 'credit-card'"
                    :disabled="loadingRefuseCard"
                    v-else
                >
                    <template v-if="loadingRefuseCard">
                        <Spinner size="1x" />
                        <span class="ms-1">In progress…</span>
                    </template>
                    <span v-else>Disallow card issuing</span>
                </DropdownItem>
                <DropdownItem
                    confirm
                    @confirm="sendGroupWelcomeMail"
                    icon="envelope"
                    :disabled="!group.id"
                    text="Welcome mail will be sent to all users"
                    >Send welcome mail</DropdownItem
                >
                <!--DropdownItem @click="startKYB()" icon="file-signature">Start KYB process</DropdownItem-->
                <DropdownItem
                    confirm
                    @confirm="deleteGroup"
                    icon="trash-alt"
                    class="text-danger"
                    v-if="$store.getters.userHasPermission('manageGroups')"
                >
                    Delete group
                </DropdownItem>
            </Dropdown>

            <!-- Update group -->
            <Modal v-if="isModalOpen('updateGroup')" @close="closeModal('updateGroup')" title="Update group">
                <UpdateGroup :id="group.id" @close="closeModal('updateGroup')" @submitted="groupUpdated" />
            </Modal>

            <!-- Update responsible -->
            <Modal
                v-if="isModalOpen('updateResponsible')"
                @close="closeModal('updateResponsible')"
                title="Update responsible"
            >
                <Responsible :id="group.id" @submitted="responsibleUpdated"></Responsible>
            </Modal>

            <!-- Attach billing account -->
            <Modal
                v-if="isModalOpen('attachBillingAccount')"
                @close="closeModal('attachBillingAccount')"
                title="Attach billing account"
            >
                <AttachBillingAccount :group="group.id" @submitted="billingAccountAttached"></AttachBillingAccount>
            </Modal>

            <!-- Create user -->
            <Modal v-if="isModalOpen('createUser')" @close="closeModal('createUser')" title="Create a user">
                <UpdateUser :values="{ groups: [group] }" @submitted="userCreated"></UpdateUser>
            </Modal>

            <!-- Create license -->
            <Modal
                v-if="isModalOpen('createLicense')"
                @close="closeModal('createLicense')"
                title="Create license for the group"
            >
                <CreateLicense
                    :group="group.id"
                    :billingAccounts="billingAccounts"
                    @submitted="licenseCreated"
                ></CreateLicense>
            </Modal>

            <!-- Extend wallets transactions -->
            <Modal
                v-if="isModalOpen('extendWalletTransactions')"
                @close="closeModal('extendWalletTransactions')"
                title="Extend wallet transactions"
            >
                <ExtendWalletTransactions :group="group" @submitted="closeModal('extendWalletTransactions')" />
            </Modal>

            <!-- KYB -->
            <Modal v-if="isModalOpen('kyb')" @close="closeModal('kyb')" title="KYB Process">
                <KYB :reference="kybResponse.reference" :token="kybResponse.token"></KYB>
            </Modal>
        </template>
    </Panel>
</template>

<script>
    import {
        Panel,
        Tabs,
        Tab,
        Sheet,
        Row,
        ID,
        Alerts,
        Dropdown,
        DropdownItem,
        Modal,
        Boolean,
        Spinner,
        Datatable,
        Table,
    } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import Address from '@/components/Address'
    import Brand from '@/components/Brand'
    import Users from '@/components/Users'
    import Cards from '@/components/Cards'
    import Wallets from '@/components/Wallets'
    import Bookings from '@/components/layout/Tabs/Bookings'
    import Store from '@/components/layout/Store'
    import axios from 'axios'
    import UpdateGroup from './UpdateGroup'
    import KYB from './KYB'
    import UpdateUser from '@/components/Users/UpdateUser'
    import Responsible from './Responsible'
    import Licenses from './Licenses'
    import Rules from './Rules'
    import Administrators from './Administrators'
    import AttachBillingAccount from '@/components/Groups/AttachBillingAccount'
    import CreateLicense from '@/components/Groups/CreateLicense'
    import ExtendWalletTransactions from '@/components/Groups/ExtendWalletTransactions'
    import TabsMixin from '../../mixins/TabsMixin'
    import CardAuthorizations from '../CardAuthorizations/index'
    import CardTransactions from '../CardTransactions/index'
    import Documents from './Documents'
    import moment from 'moment'
    import ExpenseRequests from '@/components/ExpenseRequests'
    import PayoutRequests from '@/components/PayoutRequests'
    import GroupSource from '@/components/Groups/source.vue'
    import TypeRow from '@/components/Types/TypeRow.vue'
    import CreditPolicyStatus from './CreditPolicyStatus.vue'

    export default {
        name: 'GroupPanel',
        props: {
            id: Number,
        },
        data() {
            return {
                loading: true,
                loadingExportUsers: false,
                group: null,
                wallets: {},
                kybResponse: null,
                loadingAllowCard: false,
                loadingRefuseCard: false,
                billingAccounts: null,
                pagination: {
                    current_page: 1,
                },
                loadingBillingAccounts: true,
                search: null,
            }
        },
        components: {
            TypeRow,
            Table,
            GroupSource,
            Address,
            Administrators,
            AttachBillingAccount,
            Documents,
            ExpenseRequests,
            Panel,
            Sheet,
            Row,
            Tabs,
            Tab,
            Users,
            Cards,
            Bookings,
            ID,
            Store,
            UpdateGroup,
            Dropdown,
            DropdownItem,
            Modal,
            Boolean,
            UpdateUser,
            KYB,
            Responsible,
            Licenses,
            Rules,
            Wallets,
            Spinner,
            CreateLicense,
            ExtendWalletTransactions,
            Datatable,
            CardAuthorizations,
            CardTransactions,
            PayoutRequests,
            Brand,
            CreditPolicyStatus,
        },
        mixins: [Modals, TabsMixin],
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData() {
                this.loading = true

                // set requests
                const requestGroup = this.$api.groups.show(this.id, {
                    includes: [
                        'access_stores',
                        'iban',
                        'representative',
                        'available_types',
                        'issuing',
                        'issuing.funding_instructions',
                        'issuing.credit_policy',
                    ],
                })
                const requestWallets = this.$api.groups.getWallets(this.id)

                // make requests
                axios
                    .all([requestGroup, requestWallets])
                    .then(
                        axios.spread((...responses) => {
                            const responseGroup = responses[0]
                            const responseWallets = responses[1]
                            // set data
                            this.group = responseGroup
                            this.wallets = responseWallets
                            this.fetchBillingAccounts()
                        })
                    )
                    .finally(() => {
                        this.loading = false
                    })
            },
            fetchBillingAccounts(search) {
                this.loadingBillingAccounts = true
                this.search = search
                this.$api.groups
                    .getBillingAccounts(this.id, {
                        search: search ? search : undefined,
                        page: this.pagination.current_page,
                    })
                    .then((response) => {
                        this.billingAccounts = response.data
                        this.pagination = response.meta.pagination
                    })
                    .finally(() => {
                        this.loadingBillingAccounts = false
                    })
            },
            deleteGroup() {
                this.$api.groups.delete(this.id).then(() => {
                    Alerts.notificationSuccess('Successfully deleted')
                    this.closePanel({ refresh: true })
                })
            },
            closePanel(payload) {
                this.$emit('closePanel', payload)
            },
            groupUpdated() {
                this.fetchData()
                this.closeModal('updateGroup')
            },
            handleExportUsers() {
                if (this.loadingExportUsers) return
                this.loadingExportUsers = true
                this.$exporterApi.groups
                    .getUsers(this.id)
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response]))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute(
                            'download',
                            `export-group-${this.id}-users-${moment().format('YYYYMMDD')}.xlsx`
                        )
                        document.body.appendChild(link)
                        link.click()
                    })
                    .catch((error) => {
                        Alerts.notificationError(
                            error && error.response && error.response.data && error.response.data.message
                                ? error.response.data.message
                                : 'An error has occurred, the export was not possible.'
                        )
                    })
                    .finally(() => {
                        this.loadingExportUsers = false
                    })
            },
            userCreated(user) {
                this.fetchData()
                this.closeModal('createUser')
                this.addUserToGroup(user)
            },
            addUserToGroup(user) {
                this.$api.groups
                    .addUser(this.id, {
                        user_id: user.id,
                    })
                    .then(() => {
                        this.fetchData()
                    })
            },
            allowCards() {
                this.loadingAllowCard = true
                this.$api.groups
                    .allowCards(this.id)
                    .then(() => {
                        this.fetchData()
                    })
                    .catch((error) => {
                        Alerts.notificationError(error.response.data.message)
                    })
                    .finally(() => {
                        this.loadingAllowCard = false
                    })
            },
            refuseCards() {
                this.loadingRefuseCard = true
                this.$api.groups
                    .refuseCards(this.id)
                    .then(() => {
                        this.fetchData()
                    })
                    .catch((error) => {
                        Alerts.notificationError(error.response.data.message)
                    })
                    .finally(() => {
                        this.loadingRefuseCard = false
                    })
            },
            startKYB() {
                this.$api.groups
                    .startKYB(this.id)
                    .then((response) => {
                        this.kybResponse = response
                        this.openModal('kyb')
                    })
                    .catch((error) => {
                        Alerts.notificationError(error.response.data.message)
                    })
            },
            responsibleUpdated() {
                this.fetchData()
                this.closeModal('updateResponsible')
            },
            openUser(user) {
                this.openPanel('userPanel', user.id)
            },
            openType(type) {
                this.openPanel('TypePanel', type.id)
            },
            detachBillingAccount(billingAccountId) {
                this.$api.groups
                    .detachBillingAccount(this.id, {
                        billing_account_id: billingAccountId,
                    })
                    .then(() => {
                        this.fetchBillingAccounts()
                    })
                    .catch((error) => {
                        Alerts.notificationError(error.response.data.message)
                    })
            },
            openBillingAccount(id) {
                this.openPanel('billingAccountPanel', id)
            },
            sendGroupWelcomeMail() {
                this.$api.groups.sendWelcomeMail(this.group.id).then(() => {
                    Alerts.notificationSuccess('Mail will be sent if never sent')
                })
            },
            billingAccountAttached() {
                this.closeModal('attachBillingAccount')
                this.fetchBillingAccounts()
            },
            licenseCreated() {
                if (this.$refs['groupTabs']) this.$refs['groupTabs'].selectTab({ name: 'Licenses' })
                if (this.$refs['licenses']) this.$refs['licenses'].fetchData()
                this.closeModal('createLicense')
            },
            parseExternalUrl(app, id) {
                switch (app) {
                    case 'stripe':
                        return `https://dashboard.stripe.com/${id}/`
                    default:
                        return null
                }
            },
        },
        computed: {
            mapLink() {
                return `https://www.google.com/maps?q=${this.group.address.address ?? this.group.address.line1} ${
                    this.group.address.zipcode
                } ${this.group.address.city} ${this.group.address.country}`
            },
            groupExternalID() {
                if (this.group?.external_id?.billing_api) {
                    const tempObject = this.group.external_id
                    delete tempObject.billing_api
                    return tempObject
                } else {
                    return this.group.external_id
                }
            },
            haveBillingAccounts() {
                return this.group.has_billing_account
            },
            formattedCreditPolicyQuoteRefs() {
                try {
                    const refs = this.group.issuing.credit_policy.metadata.quote_refs
                    return refs ? JSON.parse(refs) : []
                } catch (error) {
                    return []
                }
            },
        },
    }
</script>
