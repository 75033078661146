var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Datatable',{attrs:{"name":"Group rules","pagination":_vm.pagination,"loading":_vm.loading,"hideActions":_vm.hideActions,"hover":false,"searchClass":"d-none"},on:{"update:pagination":function($event){_vm.pagination=$event},"search":_vm.fetchData},scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('tr',[_c('th',[_vm._v("Type")]),_c('th',[_vm._v("Rule")])])]},proxy:true}])},_vm._l((_vm.defaultRules),function(rule,index){return _c('tr',{key:'rule-' + index,attrs:{"valign":"middle"}},[[_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('IconWallet',{attrs:{"category":rule.type.technical_name}}),_c('span',{staticClass:"ms-2"},[_vm._v(_vm._s(rule.type.name.en))])],1)]),_c('td',[_c('div',{staticClass:"d-flex align-items-start flex-column"},_vm._l((_vm.settingsRules),function(typeRule,name,tIndex){return (typeRule.canUses.includes(rule.type.technical_name))?_c('div',{key:'typeRule-' + tIndex,staticClass:"d-flex align-items-center justify-content-between w-100"},[(name === 'allowance_subscription_percentage')?_c('div',{staticClass:"d-flex justify-content-between align-items-center w-100"},[_c('Checkbox',{class:['mb-0', { 'pe-none opacity-50': !_vm.sendData }],attrs:{"value":!!rule.rules[name],"small":""},on:{"input":function($event){rule.rules[name] = !rule.rules[name]
                                        ? true
                                        : _vm.updateRuleValue(false, index, name, _vm.messages.percentage)}}},[_vm._v(_vm._s(typeRule.name))]),_c('div',{staticClass:"d-flex align-items-center",class:{ 'pe-none opacity-50': !rule.rules[name] }},[_c('Input',{staticClass:"input-fields",attrs:{"type":"number","min":"50","max":"100","name":"allowance_subscription_percentage","placeholder":"0","hasError":_vm.hasSubscriptionPercentageError},on:{"input":function($event){return _vm.handleSubscriptionPercentage($event, index, name, _vm.messages.percentage)}},model:{value:(_vm.subscriptionPercentageBuffer),callback:function ($$v) {_vm.subscriptionPercentageBuffer=$$v},expression:"subscriptionPercentageBuffer"}}),_c('span',{staticClass:"input-fields-symbol d-inline-block text-center ms-1"},[_vm._v("%")])],1)],1):(name === 'maximum_authorization_amount')?_c('div',{staticClass:"d-flex justify-content-between align-items-center w-100"},[_c('Checkbox',{class:['mb-0', { 'pe-none opacity-50': !_vm.sendData }],attrs:{"value":!!rule.rules[name],"small":""},on:{"input":function($event){rule.rules[name] = !rule.rules[name]
                                        ? true
                                        : _vm.updateRuleValue(null, index, name, _vm.messages.default)}}},[_vm._v(_vm._s(typeRule.name))]),_c('div',{staticClass:"d-flex align-items-center",class:{ 'pe-none opacity-50': !rule.rules[name] }},[_c('Input',{staticClass:"input-fields",attrs:{"type":"number","min":"0","max":"1000","name":"maximum_authorization_amount","placeholder":"0","hasError":_vm.hasMaximumAuthorizationAmountError},on:{"input":function($event){return _vm.handleMaximumAuthorizationAmount($event, index, name)}},model:{value:(_vm.maximumAuthorizationAmountBuffer),callback:function ($$v) {_vm.maximumAuthorizationAmountBuffer=$$v},expression:"maximumAuthorizationAmountBuffer"}}),_c('span',{staticClass:"input-fields-symbol d-inline-block text-center ms-1"},[_vm._v("€")])],1)],1):(name === 'auto_extend_deposit')?_c('Checkbox',{class:{ 'pe-none opacity-50': !_vm.sendData },attrs:{"value":typeof rule.rules[name] === 'boolean' ? rule.rules[name] : true,"small":""},on:{"input":function($event){return _vm.updateRuleValue(
                                    typeof rule.rules[name] === 'boolean' ? !rule.rules[name] : false,
                                    index,
                                    name,
                                    _vm.messages.default
                                )}}},[_vm._v(_vm._s(typeRule.name))]):_c('Checkbox',{class:{ 'pe-none opacity-50': !_vm.sendData },attrs:{"value":rule.rules[name],"small":""},on:{"input":function($event){return _vm.updateRuleValue(!rule.rules[name], index, name, _vm.messages.default)}}},[_vm._v(_vm._s(typeRule.name))])],1):_vm._e()}),0)])]],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }