<template>
    <Panel title="Card transaction" :loading="loading" @closePanel="$emit('closePanel', { refresh: hasSubmitted })">
        <Alert color="danger" icon="info-circle" v-if="transaction && !transaction.authorization"
            >This transaction has not been authorized</Alert
        >
        <Tabs v-if="transaction">
            <!-- Card authorization -->
            <Tab name="Card transaction" :selected="true">
                <div class="mb-2">
                    <CardTransactionStatus v-if="transaction.status" :status="transaction.status" class="me-1" />
                    <Boolean
                        v-model="transaction.can_switch_payment_methods.status"
                        textOn="Switch available"
                        textOff="Switch unavailable"
                    />
                </div>

                <Sheet name="Details" class="mb-2">
                    <Row name="Date of transaction">{{
                        transaction.transaction_at | formatDate('DD/MM/YYYY HH:mm:ss')
                    }}</Row>
                    <Row name="Amount">{{ transaction.amount | currency }}</Row>
                    <Row name="User" v-if="transaction.user">
                        <a href="#" @click.prevent="openUser(transaction.user)">{{ transaction.user.name }}</a>
                    </Row>
                    <Row name="Card" v-if="transaction.card">
                        <a href="#" @click.prevent="openCard(transaction.card)"
                            >•••• {{ transaction.card.last_four }} ({{ transaction.card.name_on_card }})</a
                        >
                    </Row>
                    <Row name="Created at">{{ transaction.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</Row>
                    <Row name="Allowed types">
                        <span class="me-2" v-for="allowedType in transactionAllowedTypes" :key="allowedType.id">
                            <span>{{ allowedType.name[$store.state.lang.displayLanguage] }}</span>
                        </span>
                    </Row>
                </Sheet>

                <Sheet name="Payment methods" class="mb-2">
                    <Table striped borderless hover class="mb-4">
                        <tr v-for="(method, index) in paymentMethods" :key="index">
                            <td><SelectedPaymentMethod :paymentMethod="method" :key="index" /></td>
                        </tr>
                    </Table>
                </Sheet>

                <Sheet name="Authorization" class="mb-2" v-if="transaction.authorization">
                    <Row name="ID"
                        ><a href="#" @click.prevent="openAuthorization(transaction.authorization)"
                            ><code>{{ transaction.authorization.id }}</code></a
                        ></Row
                    >
                    <Row name="Date">{{
                        transaction.authorization.transaction_at | formatDate('DD/MM/YYYY HH:mm:ss')
                    }}</Row>
                    <Row name="Amount">{{ transaction.authorization.amount | currency }}</Row>
                </Sheet>

                <Sheet name="Switch payment methods" class="mb-2" v-if="transaction.can_switch_payment_methods">
                    <Row name="Status">
                        <Boolean v-model="transaction.can_switch_payment_methods.status" />
                    </Row>
                    <Row name="Reason" v-if="!transaction.can_switch_payment_methods.status">
                        <span v-if="$te('shared.reasons.' + transaction.can_switch_payment_methods.reason)">{{
                            $t('shared.reasons.' + transaction.can_switch_payment_methods.reason)
                        }}</span>
                        <span v-else>{{ transaction.can_switch_payment_methods.reason }}</span>
                    </Row>
                </Sheet>

                <Sheet name="Merchant" class="mb-2">
                    <Row name="ID" v-if="transaction.merchant.id"> {{ transaction.merchant.id }}</Row>
                    <Row name="Category" v-if="transaction.merchant.category"> {{ transaction.merchant.category }}</Row>
                    <Row name="Name" v-if="transaction.merchant.name">{{ transaction.merchant.name }}</Row>
                    <Row name="Address" v-if="transaction.merchant.address"
                        >{{ transaction.merchant.address.city }} {{ transaction.merchant.address.country }}</Row
                    >
                    <Row name="Identified partner" v-if="transaction.partner">
                        <div>
                            <a href="#" @click.prevent="openPartner(transaction.partner)">{{
                                transaction.partner.name[$store.state.lang.displayLanguage]
                            }}</a>
                        </div>
                    </Row>
                </Sheet>

                <ID name="Hexeko" :value="transaction.id" />
                <ID
                    v-if="transaction.external_id && transaction.card && transaction.card.issuer.external_id.stripe"
                    name="Stripe"
                    :url="`https://dashboard.stripe.com/${transaction.card.issuer.external_id.stripe}/issuing/transactions/${transaction.external_id}`"
                    :value="transaction.external_id"
                    class="ms-3"
                />
            </Tab>

            <!-- Payment transactions -->
            <Tab name="Payment transactions">
                <Transactions :params="{ payable_type: 'card_transaction', payable_id: transaction.id }" />
            </Tab>

            <!-- Payment intents -->
            <Tab name="Payment intents">
                <PaymentIntents :params="{ payable_type: 'card_transaction', payable_id: transaction.id }" />
            </Tab>

            <!-- History -->
            <Tab name="History">
                <History :statuses="transaction.statuses" v-slot:default="slotProps">
                    <CardTransactionStatus :status="slotProps.status" />
                </History>
            </Tab>
        </Tabs>
        <template v-slot:actions>
            <Dropdown small title="Actions" position="right">
                <DropdownItem @click="openModal('switch')" icon="random">Switch</DropdownItem>
            </Dropdown>
            <!-- Update type -->
            <Modal
                v-if="isModalOpen('switch') && transaction"
                @close="closeModal('switch')"
                title="Switch payment methods"
            >
                <SwitchPaymentMethods
                    :id="transaction.id"
                    :paymentMethods="paymentMethods"
                    @submitted="switchSubmitted"
                />
            </Modal>
        </template>
    </Panel>
</template>

<script>
    import {
        Alert,
        Panel,
        Tabs,
        Tab,
        ID,
        Sheet,
        Row,
        Table,
        Boolean,
        Dropdown,
        DropdownItem,
        Modal,
    } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import axios from 'axios'
    import CardTransactionStatus from '@/components/CardTransactions/status'
    import History from '@/components/layout/History'
    import Transactions from '@/components/PaymentTransactions'
    import PaymentIntents from '@/components/PaymentIntents'
    import SwitchPaymentMethods from '@/components/CardTransactions/SwitchPaymentMethods'
    import SelectedPaymentMethod from '@/components/layout/SelectedPaymentMethod'

    export default {
        name: 'CardTransactionPanel',
        mixins: [Modals],
        components: {
            Alert,
            PaymentIntents,
            CardTransactionStatus,
            Transactions,
            Panel,
            Tabs,
            Tab,
            ID,
            Sheet,
            Row,
            History,
            Table,
            Boolean,
            SwitchPaymentMethods,
            Dropdown,
            DropdownItem,
            Modal,
            SelectedPaymentMethod,
        },
        props: {
            id: String,
        },
        data() {
            return {
                hasSubmitted: false,
                loading: true,
                paymentMethods: null,
                transaction: null,
                transactionAllowedTypes: null,
            }
        },
        methods: {
            fetchData() {
                this.loading = true
                // set requests
                const cardTransactionPaymentMethods = this.$api.cards.getPaymentMethods(this.id, {})
                const requestTransaction = this.$api.cards.showCardTransactions(this.id, {
                    includes: [
                        'user',
                        'card',
                        'statuses',
                        'authorization',
                        'payment_transactions',
                        'can_switch_payment_methods',
                        'card.issuer',
                        'external_id',
                        'merchant',
                        'card.issuer.external_id',
                    ],
                })
                const requestAllowedTypes = this.$api.cards.getCTAllowedTypes(this.id, {})
                // make requests
                axios
                    .all([requestTransaction, requestAllowedTypes, cardTransactionPaymentMethods])
                    .then(
                        axios.spread((...responses) => {
                            const responseTransaction = responses[0]
                            const responseAllowedTypes = responses[1].data
                            const responsePaymentMethods = responses[2].data
                            // set data
                            this.transaction = responseTransaction
                            this.transactionAllowedTypes = responseAllowedTypes
                            this.paymentMethods = responsePaymentMethods
                        })
                    )
                    .finally(() => {
                        // stop loading
                        this.loading = false
                    })
            },
            openPartner(partner) {
                this.openPanel('partnerPanel', partner.id)
            },
            openUser(user) {
                this.openPanel('userPanel', user.id)
            },
            openCard(card) {
                this.openPanel('cardPanel', card.id)
            },
            openAuthorization(authorization) {
                this.openPanel('cardAuthorizationPanel', authorization.id)
            },
            switchSubmitted() {
                this.hasSubmitted = true
                this.closeModal('switch')
                setTimeout(this.fetchData, 500)
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
