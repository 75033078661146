<template>
    <Datatable
        name="Credit limits"
        :hover="false"
        :hideActions="hideActions"
        :pagination.sync="pagination"
        :loading="loading"
        searchClass="d-none"
    >
        <template v-slot:thead>
            <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Amount<br />(remaining / initial)</th>
                <th>Eligibility<br />(start / end)</th>
                <th>Credit<br />(start / end)</th>
            </tr>
        </template>
        <tr v-for="creditLimit in creditLimits" :key="creditLimit.id" valign="middle">
            <td>
                <code class="d-block credit-limit-id">{{ creditLimit.id }}</code>
            </td>
            <td>{{ creditLimit.created_at | formatDate }}</td>
            <td>{{ creditLimit.remaining_amount | currency }} / {{ creditLimit.initial_amount | currency }}</td>
            <td>
                <ul class="list-unstyled m-0">
                    <li>
                        <Icon icon="hourglass-start" class="me-1" />{{
                            creditLimit.eligibility_started_at | formatDate
                        }}
                    </li>
                    <li>
                        <Icon icon="hourglass-end" class="me-1" />{{ creditLimit.eligibility_ended_at | formatDate }}
                    </li>
                </ul>
            </td>
            <td>
                <ul class="list-unstyled m-0">
                    <li>
                        <Icon icon="hourglass-start" class="me-1" />{{ creditLimit.credit_started_at | formatDate }}
                    </li>
                    <li><Icon icon="hourglass-end" class="me-1" />{{ creditLimit.credit_ended_at | formatDate }}</li>
                </ul>
            </td>
        </tr>
    </Datatable>
</template>

<script>
    import { Alerts, Datatable } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'

    export default {
        name: 'CreditLimits',
        components: {
            Datatable,
        },
        data() {
            return {
                creditLimits: [],
                loading: true,
                pagination: { current_page: 1 },
            }
        },
        props: {
            hideActions: Boolean,
            params: {
                type: Object,
                default: () => ({}),
            },
        },
        methods: {
            fetchData() {
                this.loading = true
                const params = this.mergeParams({
                    page: this.pagination.current_page,
                })
                this.$api.creditLimits
                    .get(params)
                    .then((response) => {
                        this.creditLimits = response.data
                        this.pagination = response.meta.pagination
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response && error.response.data
                                ? error.response.data.message
                                : 'An error occurred while trying to fetch credit limits'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>

<style type="text/css" scoped>
    .credit-limit-id {
        max-width: 150px;
    }
</style>
