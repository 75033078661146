<template>
    <Datatable
        name="Card transactions"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
    >
        <template v-slot:filters>
            <Dropdown class="me-1 mb-1" color="secondary" title="Filter by status" small>
                <li v-for="(avStatus, index) in statuses" :key="index">
                    <a
                        href="#"
                        class="dropdown-item text-xs"
                        :class="status === index ? 'bg-primary text-white' : 'bg-white'"
                        @click.prevent="filterByStatus(index)"
                        >{{ avStatus.name }}</a
                    >
                </li>
            </Dropdown>
            <Button
                class="me-1"
                @click="toggleFilterWithAuthorization"
                :color="
                    filterWithAuthorization ? 'success' : filterWithAuthorization === false ? 'danger' : 'secondary'
                "
                small
            >
                With authorization
            </Button>
            <Button
                class="me-1"
                @click="toggleFilterDoesntHavePartner"
                :color="doesntHavePartner ? 'success' : doesntHavePartner === false ? 'danger' : 'secondary'"
                small
            >
                Without partner
            </Button>
            <Button
                class="me-1"
                @click="toggleFilterFullyDebited"
                :color="fullyDebited ? 'success' : fullyDebited === false ? 'danger' : 'secondary'"
                small
            >
                Fully debited
            </Button>
            <DateRange label="Transaction date" v-model="transactionDateRange" time />
            <DateRange label="Creation date" v-model="creationDateRange" time />
        </template>
        <template v-slot:thead>
            <tr>
                <th>Date</th>
                <th>User</th>
                <th>Merchant</th>
                <th>Partner</th>
                <th>Amount</th>
                <th>Status</th>
                <th></th>
            </tr>
        </template>
        <template v-slot:default>
            <tr
                v-for="(transaction, index) in transactions"
                @click="openTransaction(transaction)"
                :key="'transaction-' + index"
            >
                <td>{{ transaction.transaction_at | formatDate }}</td>
                <td>
                    <template v-if="transaction.user"><Icon icon="user" /> {{ transaction.user.name }}</template>
                </td>
                <td>
                    <div class="text-truncate" style="max-width: 200px">
                        <template v-if="transaction.merchant.name"
                            >{{ transaction.merchant.name }}
                            <span v-if="transaction.merchant.id">({{ transaction.merchant.id }})</span>
                        </template>
                        <template v-else>-</template>
                    </div>
                </td>
                <td>
                    <Badge
                        v-if="transaction.partner"
                        color="success"
                        icon="handshake"
                        class="text-truncate"
                        style="max-width: 200px"
                    >
                        {{ transaction.partner.name[$store.state.lang.displayLanguage] }}
                    </Badge>
                    <Badge v-else color="secondary" icon="question-circle">Partner unknown</Badge>
                </td>
                <td>{{ transaction.amount | currency }}</td>
                <td><CardTransactionStatus :status="transaction.status" /></td>
                <td class="text-right"><Icon icon="angle-right" /></td>
            </tr>
        </template>
    </Datatable>
</template>

<script>
    import { Badge, Button, Datatable, DateRange, Dropdown, Icon } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import CardTransactionStatus from '@/components/CardTransactions/status'
    import statuses from '@/components/CardTransactions/statuses'
    import { formatBoolean } from '@/utils/query'
    import moment from 'moment'

    export default {
        name: 'CardTransactions',
        components: {
            Badge,
            Button,
            CardTransactionStatus,
            Datatable,
            DateRange,
            Dropdown,
            Icon,
        },
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Badge,
        },
        data() {
            return {
                loading: true,
                search: null,
                status: null,
                statuses: statuses,
                pagination: {
                    current_page: 1,
                },
                transactions: [],
                doesntHavePartner: null,
                fullyDebited: null,
                filterWithAuthorization: null,
                transactionDateRange: {
                    start: '',
                    end: '',
                },
                creationDateRange: {
                    start: '',
                    end: '',
                },
            }
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData(search) {
                // Set params
                this.search = search
                this.loading = true

                const transaction_date_from = this.formatDate(this.transactionDateRange.start)
                const transaction_date_to = this.formatDate(this.transactionDateRange.end)
                const creation_date_from = this.formatDate(this.creationDateRange.start)
                const creation_date_to = this.formatDate(this.creationDateRange.end)
                let params = this.mergeParams({
                    search: this.search,
                    statuses: [this.status],
                    page: this.pagination.current_page,
                    order_by: 'created_at',
                    sort_by: 'desc',
                    doesnt_have_partner: formatBoolean(this.doesntHavePartner),
                    fully_debited: formatBoolean(this.fullyDebited),
                    with_authorization: formatBoolean(this.filterWithAuthorization),
                    ...(this.params.groupId && { group: this.params.groupId }),
                    includes: ['partner', 'user', 'authorization', 'merchant'],
                    transaction_date_from,
                    transaction_date_to,
                    creation_date_from,
                    creation_date_to,
                    isCancellable: true,
                })
                // Search function
                return this.fetchFunction(params)
                    .then((response) => {
                        if (response) {
                            this.transactions = response.data
                            this.pagination = response.meta.pagination
                            this.loading = false
                        }
                    })
                    .catch((error) => {
                        if (error.code !== 'ERR_CANCELED') {
                            this.loading = false
                            throw error
                        }
                    })
            },
            fetchFunction(params) {
                if (this.params.card) {
                    return this.$api.cards.getCardTransactions(this.params.card, params)
                }
                return this.$api.cards.getAllCardsTransactions(params)
            },
            filterByStatus(status) {
                this.status = this.status === status ? null : status
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            toggleFilterDoesntHavePartner() {
                if (this.doesntHavePartner === null) this.doesntHavePartner = true
                else if (this.doesntHavePartner === true) this.doesntHavePartner = false
                else this.doesntHavePartner = null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            toggleFilterFullyDebited() {
                if (this.fullyDebited === null) this.fullyDebited = true
                else if (this.fullyDebited === true) this.fullyDebited = false
                else this.fullyDebited = null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            toggleFilterWithAuthorization() {
                if (this.filterWithAuthorization === null) this.filterWithAuthorization = true
                else if (this.filterWithAuthorization === true) this.filterWithAuthorization = false
                else this.filterWithAuthorization = null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            openTransaction(transaction) {
                let panel = this.openPanel('CardTransactionPanel', transaction.id)
                panel.promise.then((result) => {
                    if (result && result.refresh) this.fetchData(this.search)
                })
            },
            formatDate(date) {
                if (!date) return ''
                return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss')
            },
        },
        watch: {
            creationDateRange: {
                handler() {
                    this.pagination.current_page = 1
                    this.fetchData()
                },
            },
            transactionDateRange: {
                handler() {
                    this.pagination.current_page = 1
                    this.fetchData()
                },
            },
        },
    }
</script>
