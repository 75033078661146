import { userManager } from '../oidc.js';

export default {
    install(Vue, { apis }) {
        if (apis.length == 0) return console.error('No APIs provided');

        // Interceptor: Request
        apis.forEach((api) => {
            api.interceptors.request.use(
                async (config) => {
                    const user = await userManager.getUser();
                    if (user && user.access_token) {
                        config.headers['Authorization'] = `Bearer ${user.access_token}`;
                    } else {
                        return Promise.reject({
                            config: config,
                            response: {
                                status: 401,
                                message: 'Unauthorized (not authenticated)',
                            },
                        });
                    }
                    return config;
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        });
    },
};
