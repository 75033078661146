<template>
    <Card v-if="wallet && wallet.source" class="d-flex flex-column flex-md-row justify-content-between">
        <!-- Details -->
        <div>
            <TypesIcons :types="getTypesArrayFromWallet(wallet.source)" position="left" />
            <h5 class="fs-6 fw-normal mt-1 mb-0">
                <span v-if="walletName">{{ walletName }}</span>
                <code v-if="wallet.source.id" class="text-muted"
                    ><small> (ID {{ wallet.source.id }})</small></code
                >
            </h5>
            <div class="text-xs text-muted">
                <div v-if="wallet.source.source && wallet.source.source.name" class="mt-1">
                    <Icon :icon="wallet.source.source_type === 'user' ? 'user' : 'building'" />
                    {{ $t('user-account.wallet.fundedBy') }} {{ wallet.source.source.name }}
                </div>
                <div class="mt-1" v-for="(balance, index) in balances" :key="index">
                    <Icon icon="clock" />
                    <template v-if="balances.length > 1">
                        {{ balance.balance }}&nbsp;&euro; {{ $t('user-account.wallet.expiresMultiple') }}
                        {{ balance.end_date | formatDate('DD/MM/YYYY') }}
                    </template>
                    <template v-else>
                        {{ $t('user-account.wallet.expires') }} {{ balance.end_date | formatDate('DD/MM/YYYY') }}
                    </template>
                </div>
                <Progress
                    v-if="wallet.source.initial_spendable_amount && !useBalance"
                    class="mt-1"
                    :total="wallet.source.initial_spendable_amount"
                    :count="totalSpent"
                    :label="' '"
                />
            </div>
        </div>
        <!-- Balances & amount -->
        <div class="mt-2 mt-md-0">
            <span v-if="useBalance || showWithoutBalance" class="text-primary text-md"
                >{{ wallet.balance | currency
                }}<span class="font-weight-light text-xs"> / {{ total | currency }}</span></span
            >
            <ul v-else class="list-unstyled m-0 text-end text-sm">
                <li class="text-primary">
                    <span class="text-xxs text-muted">Selectable</span> {{ wallet.amount_selectable | currency }}
                </li>
                <li class="text-primary">
                    <span class="text-xxs text-muted">Balance</span> {{ wallet.balance | currency }}
                </li>
                <li class="mt-1">
                    <Input
                        v-model="manualAmount"
                        @input="handleManualAmountInput"
                        class="manual-amount text-end"
                        :hasError="hasManualAmountError"
                        :min="0"
                        :max="wallet.amount_selectable"
                        placeholder="Amount to debit"
                        step="0.01"
                        type="number"
                        small
                    />
                </li>
            </ul>
        </div>
    </Card>
</template>

<script>
    import { Input, Progress } from '@tech_hexeko/design-system'
    import Card from '@tech_hexeko/hexeko-user-account/src/components/Card'
    import TypesIcons from '@/components/Types/Icons'
    import { getTypesArrayFromWallet } from '@/utils/query'
    let _ = require('lodash')

    export default {
        name: 'wallet',
        components: {
            Card,
            Input,
            Progress,
            TypesIcons,
        },
        data() {
            return {
                manualAmount: null,
                loading: null,
            }
        },
        props: {
            showWithoutBalance: {
                type: Boolean,
                default: false,
            },
            useBalance: {
                type: Boolean,
                default: false,
            },
            wallet: {
                type: Object,
                required: true,
            },
        },
        computed: {
            balances() {
                if (!this.wallet.source.balances) return []
                return this.wallet.source.balances.filter((b) => {
                    return b.balance > 0 && new Date(b.end_date) > new Date()
                })
            },
            hasManualAmountError() {
                if (!this.manualAmount) return false
                return this.manualAmount < 0 || this.manualAmount > this.wallet.amount_selectable
            },
            total() {
                return this.wallet.source.initial_spendable_amount && !this.useBalance
                    ? this.wallet.source.initial_spendable_amount
                    : this.wallet.source.total_deposit
            },
            totalSpent() {
                return _.round(this.wallet.source.initial_spendable_amount - this.wallet.source.amount_selectable, 2)
            },
            walletName() {
                if (this.wallet.source.types && this.wallet.source.types.length > 0) {
                    return _(this.wallet.source.types)
                        .map('name.' + this.$i18n.locale)
                        .join(', ')
                }
                return 'Wallet'
            },
        },
        methods: {
            getTypesArrayFromWallet,
            handleManualAmountInput(amount) {
                this.$emit('amountSelected', this.wallet, Number(amount), this.hasManualAmountError)
            },
        },
    }
</script>

<style type="text/css" scoped>
    .manual-amount {
        width: 200px;
        max-width: 100%;
        margin-left: auto;
    }
</style>
