<template>
    <Badge :color="ss.color" :icon="ss.icon">{{ ss.name }}</Badge>
</template>
<script>
    import { Badge } from '@tech_hexeko/design-system'

    let statuses = {
        active: {
            name: 'Active',
            color: 'success',
            icon: 'check-circle',
        },
        inactive: {
            name: 'Inactive',
            color: 'secondary',
            icon: 'exclamation-circle',
        },
    }

    export default {
        name: 'CreditPolicyStatus',
        components: {
            Badge,
        },
        props: {
            status: String,
        },
        computed: {
            ss: function () {
                return statuses[this.status]
            },
        },
    }
</script>
