<template>
    <FormRoot :loading="dataLoading">
        <Field
            v-if="isNew"
            :form.sync="form"
            type="component"
            component="Users"
            name="user_id"
            placeholder="User"
            hint="User who has spent the amount"
        ></Field>
        <Field
            v-if="form.partner_id || isNew"
            :form.sync="form"
            type="component"
            component="Partners"
            name="partner_id"
            placeholder="Partner"
            hint="Partner where the expense has been made"
            :searchFunction="searchPartners"
        ></Field>
        <Field
            :form.sync="form"
            type="date"
            name="ticket_date"
            placeholder="Date of ticket"
            hint="The date printed on the ticket"
        ></Field>
        <Field
            v-if="isNew"
            :form.sync="form"
            type="text"
            name="iban"
            placeholder="IBAN"
            hint="The IBAN of the user, where the money will be refunded"
        ></Field>
        <Field
            :form.sync="form"
            type="text"
            name="amount"
            placeholder="Amount"
            hint="The amount printed on the ticket"
        ></Field>
        <Field
            :form.sync="form"
            type="select"
            :options="typeOptions"
            optionsKeyBy="value"
            optionsLabel="label"
            name="type"
            placeholder="Type"
            hint="The type associated with this request"
        ></Field>
        <template v-if="form.user_id">
            <PaymentMethodsList
                v-model="form.payment_methods"
                :user="form.user_id"
                :amount="Number(form.amount)"
                :date="form.ticket_date"
                @input="form.errors.clear('payment_methods')"
                @error="paymentError"
                hideActions
                showWithoutBalance
                useBalance
            />
            <Error v-if="form.errors.has('payment_methods')" :error="form.errors.get('payment_methods')" />
        </template>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" :loading="form.loading" @click="onSubmit">{{
                submitTitle
            }}</Submit>
        </FormActions>
    </FormRoot>
</template>

<script>
    import { Error, Field, FormActions, FormRoot, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    import PaymentMethodsList from '@/components/PaymentMethods/List'
    import { EXPENSE_REQUESTS } from '@tech_hexeko/shared/src/utils/constants.js'
    import moment from 'moment'

    export default {
        components: {
            Error,
            Field,
            FormActions,
            FormRoot,
            PaymentMethodsList,
            Submit,
        },
        mixins: [Forms],
        data() {
            return {
                fields: {
                    amount: null,
                    payment_methods: [],
                    ticket_date: null,
                    type: null,
                },
                typeOptions: EXPENSE_REQUESTS.TYPES,
            }
        },
        computed: {
            isNew() {
                return !this.id
            },
            messageSuccess() {
                return this.isNew ? 'The expense request has been created' : 'The expense request has been updated'
            },
            submitTitle() {
                return this.isNew ? 'Create' : 'Update'
            },
        },
        methods: {
            loadData() {
                return this.$api.expenseRequests
                    .show(this.id, { includes: ['user', 'partner', 'selected_payment_methods'] })
                    .then((request) => {
                        let payment_methods = request.selected_payment_methods.payment_methods.map((selected) => {
                            return {
                                id: selected.payment_method.id,
                                amount: selected.amount,
                            }
                        })
                        if (request.partner) this.fields.partner_id = null
                        this.form.fillAsOriginalData({
                            user_id: request.user.id,
                            ...(request.partner && { partner_id: request.partner }),
                            amount: request.amount,
                            ticket_date: moment(request.ticket_date).format('YYYY-MM-DD'),
                            payment_methods: payment_methods,
                            type: request.type,
                        })
                    })
            },
            searchPartners(search) {
                // To unlock draft status and check if form is submittable
                this.form.keyUp()
                return this.$api.partners.get({ search: search })
            },
            submit() {
                const data = this.$data.form.data()
                if (this.id) {
                    return this.$api.expenseRequests.update(this.id, data)
                } else {
                    return this.$api.expenseRequests.create(data)
                }
            },
            paymentError(error) {
                this.form.errors.add('payment_methods', error)
            },
        },
        watch: {
            isNew: {
                immediate: true,
                handler(isNew) {
                    if (!isNew) return
                    // Set extra fields for new ER only
                    this.fields.user_id = null
                    this.fields.iban = null
                    this.fields.partner_id = null
                },
            },
        },
    }
</script>
