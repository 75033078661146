<template>
    <FormRoot>
        <h4 class="mb-1 text-xxs text-secondary mb-2">Type(s) already identified</h4>
        <div class="pb-2">
            <Badge v-for="type in allowedTypes" :key="type.id" class="ms-1">
                {{ type.name[$store.state.lang.displayLanguage] }}
            </Badge>
        </div>
        <Field
            :form.sync="form"
            type="select"
            :options="options"
            optionsLabel="label"
            name="types"
            placeholder="Type(s)"
            hint="Additional type(s) to assign to this card authorization"
            multiple
        ></Field>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" :loading="form.loading" @click="onSubmit">
                Identify type(s)
            </Submit>
        </FormActions>
    </FormRoot>
</template>

<script>
    import { Alerts, Badge, FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        data() {
            return {
                fields: {
                    types: [],
                },
                messageSuccess: 'One or more additional types have been assigned to this authorization',
                types: [],
            }
        },
        props: {
            authorizationId: String,
            allowedTypes: Array,
        },
        mixins: [Forms],
        components: {
            Badge,
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        mounted() {
            this.fetchTypes()
        },
        computed: {
            filteredTypes() {
                return this.types.filter((type) => this.allowedTypes.every((t) => t.id !== type.id))
            },
            options() {
                return this.filteredTypes
                    .reduce((options, type) => {
                        const children = type.children.length > 0 ? type.children : []
                        return [...options, type, ...children]
                    }, [])
                    .map((type) => {
                        return {
                            ...type,
                            label: type.name[this.$store.state.lang.displayLanguage],
                        }
                    })
            },
        },
        methods: {
            searchType(search) {
                return this.$api.types.get({ search: search ? search : undefined, depth: 1, has_may_category: 1 })
            },
            async fetchTypes() {
                try {
                    const types = await this.searchType()
                    this.types = types.data
                } catch (error) {
                    const errorTxt =
                        error && error.response && error.response.data
                            ? error.response.data.message
                            : 'An error occurred while fetching the types'
                    Alerts.notificationError(errorTxt)
                }
            },
            submit() {
                const data = this.$data.form.data()
                const types = data.types?.map((type) => type.id) || []
                if (types.length > 0) {
                    return this.$api.cards.addTypesToAuthorization(this.authorizationId, { types })
                }
            },
        },
    }
</script>
