<template>
    <div>
        <div class="mb-4">
            <Medias multiple ref="medias" :value="files" @input="inputFiles">
                <template #default="{ medias }">
                    <ul class="mb-2 list-group">
                        <li
                            class="list-group-item d-flex justify-content-between"
                            v-for="media in medias"
                            :key="media.id"
                        >
                            <a :href="media.path" target="_blank">
                                <Icon icon="file-alt" /> {{ media.file_name }} ({{ media.human_readable_size }})
                            </a>
                            <button class="bg-transparent border-0" type="button" @click="removeMedia(media.id)">
                                <Icon icon="trash" />
                            </button>
                        </li>
                    </ul>
                </template>
                <template #empty>
                    <Alert icon="exclamation-triangle" class="cursor-pointer">No ticket uploaded.</Alert>
                </template>
            </Medias>
            <Error v-if="error" :error="error" />
            <Error v-if="errorTooManyFiles" :error="errorTooManyFiles" />
        </div>
        <Submit @click="updateFiles" :disabled="!isSubmittable" :loading="uploading">Update file</Submit>
    </div>
</template>

<script>
    import s3Class from '@tech_hexeko/shared/src/plugins/s3'
    import { Alert, Alerts, Error, Icon, Submit } from '@tech_hexeko/design-system'
    import Medias from '@/components/layout/Medias'
    import { getSessionOrRefresh } from '../../oidc.js'

    const MAX_FILES = 2

    export default {
        components: {
            Alert,
            Error,
            Icon,
            Medias,
            Submit,
        },
        props: {
            id: String,
            initialFiles: Array,
        },
        data() {
            return {
                newFiles: [],
                error: '',
                uploading: false,
                s3: null,
                hasChanged: false,
                files: this.initialFiles,
                maxFiles: MAX_FILES,
            }
        },
        computed: {
            totalFiles() {
                return [...this.files, ...this.newFiles]
            },
            totalFilesLength() {
                return this.totalFiles.length
            },
            hasTooManyFiles() {
                return this.totalFilesLength > MAX_FILES
            },
            errorTooManyFiles() {
                return this.hasTooManyFiles
                    ? `You can link maximum ${MAX_FILES} files to an expense request. Please remove some files.`
                    : ''
            },
            isSubmittable() {
                return (
                    (this.newFiles.length > 0 || this.hasChanged) &&
                    this.totalFilesLength > 0 &&
                    this.totalFilesLength <= MAX_FILES
                )
            },
        },
        methods: {
            inputFiles(files) {
                this.newFiles = files
                this.error = ''
            },
            fileUploader(user) {
                return async (file) => {
                    this.s3 = new s3Class(user.access_token)

                    const response = await this.s3.store(file)

                    const upload = this.$api.medias.uploadToDrive('s3', {
                        key: response.key,
                        name: file.name,
                    })
                    return upload
                }
            },

            async updateFiles() {
                this.error = ''
                let user = null
                try {
                    user = await getSessionOrRefresh()
                } catch (error) {
                    console.warn(error)
                }
                if (user && user.access_token) {
                    this.uploading = true
                    const uploadFile = this.fileUploader(user)
                    const filesToUpload = [...this.newFiles].splice(0, MAX_FILES)
                    try {
                        const uploadPromises = filesToUpload.map(({ file }) => uploadFile(file))
                        const medias = await Promise.all(uploadPromises)
                        const allFiles = [...this.files, ...medias]
                        const [first, second] = allFiles

                        await this.$api.expenseRequests.update(this.id, {
                            media: first.id,
                            ...(second && { second_media: second.id }),
                        })

                        this.$emit('submitted')
                    } catch (error) {
                        const errorMessage = this.getErrorMessage(error)
                        Alerts.notificationError(errorMessage)
                    } finally {
                        this.uploading = false
                    }
                } else {
                    Alerts.notificationError('You are not logged in, please refresh your browser')
                    this.uploading = false
                }
            },
            getErrorMessage(error) {
                if (error.response?.data?.errors?.key[0]) {
                    return error.response.data.errors.key[0]
                }
                if (error.response?.data?.message) {
                    return error.response.data.message
                }
                return 'An unexpected error occurred'
            },

            removeMedia(id) {
                const files = this.files.filter((file) => file.id !== id)
                this.files = files
            },
        },
        watch: {
            files: {
                handler(newFiles, oldFiles) {
                    this.hasChanged = newFiles !== oldFiles
                },
            },
        },
    }
</script>
