import { UserManager } from 'oidc-client-ts';

const cognitoAuthConfig = {
    authority: `https://cognito-idp.${process.env.VUE_APP_COGNITO_REGION}.amazonaws.com/${process.env.VUE_APP_COGNITO_USER_POOL_ID}`,
    client_id: process.env.VUE_APP_COGNITO_CLIENT_ID,
    post_logout_redirect_uri: process.env.VUE_APP_COGNITO_REDIRECT_URL,
    redirect_uri: process.env.VUE_APP_COGNITO_REDIRECT_URL,
    response_type: 'code',
    revokeTokensOnSignout: true,
    scope: process.env.VUE_APP_COGNITO_SCOPE,
    silentRequestTimeoutInSeconds: 5,
};

// Create a UserManager instance
export const userManager = new UserManager({
    ...cognitoAuthConfig,
});

export async function signoutRedirect() {
    // Warn other tabs to signout simultaneously
    localStorage.setItem('hexeko:loggedOut', 'true');
    await userManager.removeUser();
    await userManager.revokeTokens();
    await userManager.signoutRedirect({
        extraQueryParams: {
            client_id: process.env.VUE_APP_COGNITO_CLIENT_ID,
            logout_uri: process.env.VUE_APP_COGNITO_REDIRECT_URL,
        },
    });
}

export async function getSessionOrRefresh() {
    localStorage.setItem('hexeko:loggedOut', 'false');
    // Get session from storage or refresh
    try {
        const user = await userManager.getUser();
        if (user && user.access_token && !user.expired) return user;
        const silentUser = await userManager.signinSilent();
        if (silentUser && silentUser.access_token && !silentUser.expired) return silentUser;
        throw 'Not authenticated or session has expired';
    } catch (error) {
        throw error || 'Not authenticated or session has expired';
    }
}
