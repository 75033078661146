<template>
    <div>
        <!-- Status -->
        <Alert v-if="can_switch && !can_switch.status" class="mb-1" color="warning" icon="exclamation-circle">
            <Reason :slug="can_switch.reason" />
        </Alert>

        <!-- Force switch -->
        <div class="d-flex flex-column align-items-start justify-content-start mb-2">
            <Checkbox v-model="forceSwitch" :class="{ 'pe-none opacity-50': loading || loadingSubmit }" small
                >Do you want to force the switch?</Checkbox
            >
            <p class="text-xxs text-muted">
                You are responsible for any potential impact on third-party systems when enabling this option.
            </p>
        </div>

        <!-- Original payment methods -->
        <template v-if="paymentMethods">
            <h6 class="mb-2">Original payment methods</h6>
            <Card class="mb-4" color="light">
                <SelectedPaymentMethod
                    v-for="method in paymentMethods"
                    :key="method.id"
                    :clickable="false"
                    :paymentMethod="method"
                    small
                />
            </Card>
        </template>

        <h6 class="mb-2">Choose new payment methods</h6>
        <Spinner v-if="loading" />
        <template v-else>
            <template v-if="hasPaymentMethods">
                <!-- Payment methods -->
                <PaymentMethodsList
                    v-model="payment_methods_to_debit"
                    @completed="handleCompleted"
                    @hasErrors="handleErrors"
                    :paymentMethods="allowedPaymentMethods.payment_methods"
                    :amount="allowedPaymentMethods.amount_to_select"
                />

                <!-- Actions -->
                <Button
                    v-if="forceSwitch"
                    class="mt-2 float-right"
                    is="confirm"
                    @confirm="submit"
                    title="Are you sure you want to force the switch?"
                    :disabled="disabledSubmit || loadingSubmit"
                >
                    Force switch
                </Button>
                <Button v-else class="mt-2 float-right" @click="submit" :disabled="disabledSubmit || loadingSubmit">
                    Switch
                </Button>
            </template>
            <Alert v-else color="warning" icon="exclamation-circle">No payment methods available</Alert>
        </template>
    </div>
</template>

<script>
    import { Alert, Alerts, Button, Card, Checkbox, Spinner } from '@tech_hexeko/design-system'
    import PaymentMethodsList from '@/components/PaymentMethods/List'
    import Reason from '@/components/Reason'
    import SelectedPaymentMethod from '@/components/layout/SelectedPaymentMethod'
    import { formatBoolean } from '@/utils/query'

    export default {
        name: 'SwitchPaymentMethods',
        components: {
            Alert,
            Button,
            Card,
            Checkbox,
            PaymentMethodsList,
            Reason,
            SelectedPaymentMethod,
            Spinner,
        },
        props: {
            id: {
                type: String,
                required: true,
            },
            paymentMethods: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                allowedPaymentMethods: null,
                can_switch: null,
                completed: false,
                forceSwitch: false,
                hasErrors: false,
                loading: true,
                loadingSubmit: false,
                payment_methods_to_debit: [],
            }
        },
        computed: {
            disabledSubmit() {
                return this.payment_methods_to_debit.length === 0 || !this.completed || this.hasErrors
            },
            hasPaymentMethods() {
                return (
                    this.allowedPaymentMethods &&
                    this.allowedPaymentMethods.payment_methods &&
                    this.allowedPaymentMethods.payment_methods.length > 0
                )
            },
        },
        methods: {
            async fetchData() {
                this.loading = true
                try {
                    const requestStatus = await this.$api.cards.showCardTransactions(this.id, {
                        includes: ['can_switch_payment_methods'],
                    })

                    this.can_switch = requestStatus.can_switch_payment_methods

                    this.allowedPaymentMethods = await this.$api.cards.getPaymentMethodsAllowed(this.id, {
                        forced: formatBoolean(this.forceSwitch),
                        includes: [
                            'payment_methods.source.types.slugs',
                            'payment_methods.source.total_deposit',
                            'payment_methods.source.spendable_amount',
                        ],
                    })
                } catch (error) {
                    let errorTxt =
                        error && error.response && error.response.data
                            ? error.response.data.message
                            : 'An error occurred while trying to fetch payment methods'
                    Alerts.notificationError(errorTxt)
                } finally {
                    this.loading = false
                }
            },
            handleCompleted(state) {
                this.completed = state
            },
            handleErrors(hasErrors) {
                this.hasErrors = hasErrors
            },
            submit() {
                this.loadingSubmit = true
                return this.$api.cards
                    .switchPaymentMethods(this.id, {
                        payment_methods_to_debit: this.payment_methods_to_debit,
                        forced: formatBoolean(this.forceSwitch),
                    })
                    .then(() => {
                        this.submitted()
                        Alerts.notificationSuccess(this.forceSwitch ? 'Forced switch requested' : 'Switch requested')
                    })
                    .catch(() => {
                        Alerts.notificationError('Failed to switch')
                    })
                    .finally(() => {
                        this.loadingSubmit = false
                    })
            },
            submitted() {
                this.$emit('submitted')
            },
        },
        watch: {
            forceSwitch: {
                immediate: true,
                handler() {
                    this.fetchData()
                },
            },
        },
    }
</script>
