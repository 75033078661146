<template>
    <Panel title="Expense Request" :loading="loading" @closePanel="$emit('closePanel', { refresh: refreshAfterClose })">
        <Tabs v-if="request">
            <!-- Request -->
            <Tab name="Request" :selected="true">
                <ExpenseRequestStatus large :status="request.status" class="mb-1"></ExpenseRequestStatus>
                <Boolean
                    v-if="request.partner && request.partner.suggested_by"
                    v-model="request.partner.suggested_by"
                    textOn="Suggested partner"
                    class="badge-lg mb-1 ms-1"
                />
                <div v-if="request.status.reason" class="text-muted mb-1 text-xxs">
                    <Reason :slug="request.status.reason" />
                </div>

                <!-- Medias -->
                <div class="mt-1 mb-3">
                    <template v-if="hasMedia">
                        <div class="list-group mb-1" v-for="media in sortedMedia" :key="media.id">
                            <a
                                :href="media.path"
                                class="list-group-item list-group-item-light list-group-item-action"
                                target="_blank"
                                ><Icon icon="file-alt" class="me-2" />{{ `${media.name} (${media.file_name})` }}</a
                            >
                        </div>
                    </template>
                    <template v-else>
                        <a href="#" @click.prevent="manageFiles" class="text-decoration-none">
                            <Alert icon="exclamation-triangle" class="cursor-pointer"
                                >No ticket uploaded. <u>Add a ticket now</u>.</Alert
                            >
                        </a>
                    </template>
                </div>
                <Sheet class="mt-1" name="Activity">
                    <Row name="User" v-if="request.user">
                        <div>
                            <a href="#" @click.prevent="openUser(request.user)">{{ request.user.name }}</a>
                        </div>
                        <div>{{ request.user.email }}</div>
                        <div>{{ request.user.birthdate | formatDate('DD/MM/YYYY') }}</div>
                    </Row>
                    <Row name="User created on" v-if="request.user">
                        {{ request.user.created_at | formatDate('DD/MM/YYYY') }}
                    </Row>
                    <Row name="Group" v-if="request.group">
                        <a href="#" @click.prevent="openGroup(request.group)">{{ request.group.name }}</a>
                    </Row>
                    <Row name="Partner" v-if="request.partner && request.partner.name && request.partner.address">
                        <Address :address="request.partner.address">
                            <Row name="Name"
                                ><a href="#" @click.prevent="openPartner(request.partner)">{{
                                    request.partner.name[$store.state.lang.displayLanguage]
                                }}</a></Row
                            >
                        </Address>
                    </Row>
                    <Row name="Type selected" v-if="formattedType">{{ formattedType }}</Row>
                    <Row name="Ticket date">{{ request.ticket_date | formatDate('DD/MM/YYYY') }}</Row>
                </Sheet>
                <Sheet name="Payment">
                    <Row name="Amount spended">{{ request.amount | currency }}</Row>
                    <Row name="Amount to withdraw">{{ request.amount_to_withdraw | currency }}</Row>
                    <Row name="Commission">{{ request.fees | currency }}</Row>
                    <Row name="Amount to refund">{{ request.amount_to_refund | currency }}</Row>
                    <Row name="Payment methods">
                        <SelectedPaymentMethod
                            v-for="(method, index) in request.selected_payment_methods.payment_methods"
                            :paymentMethod="method"
                            :key="index"
                        />
                    </Row>
                    <Row name="IBAN">•••• {{ request.iban_last_four }}</Row>
                </Sheet>
                <Sheet name="Request">
                    <Row name="Created at">{{ request.created_at | formatDate }}</Row>
                    <Row name="Last update">{{ request.updated_at | formatDate }}</Row>
                </Sheet>
            </Tab>

            <!-- History -->
            <Tab name="History">
                <History :statuses="request.statuses" v-slot:default="slotProps">
                    <Badge>{{ slotProps.status.name }}</Badge>
                </History>
            </Tab>

            <!-- Payment intents -->
            <Tab name="Payment intents">
                <PaymentIntents :params="{ payable_type: 'expense_request', payable_id: request.id }" />
            </Tab>

            <!-- Payment transactions -->
            <Tab name="Transactions">
                <Transactions :params="{ payable_type: 'expense_request', payable_id: request.id }" />
            </Tab>

            <!-- All expense requests from the same User -->
            <Tab v-if="request.user && request.user.id" name="Other requests">
                <ExpenseRequests :params="{ user: request.user.id }" hideActions />
            </Tab>

            <!-- Payouts -->
            <Tab name="Payouts" v-if="$store.getters.userHasAnyPermission(['showPayouts', 'managePayouts'])">
                <PayoutRequests :params="{ source_type: 'expense_request', source_id: request.id }" />
            </Tab>
        </Tabs>

        <ID v-if="request && request.id" class="mt-4" name="Hexeko" :value="request.id" />

        <template v-slot:actions v-if="request && $store.getters.userHasPermission('manageExpenseRequests')">
            <!-- Dropdown -->
            <Dropdown small title="Actions" position="right">
                <DropdownItem @click="updateRequest" icon="edit">Update request</DropdownItem>
                <DropdownItem @click="manageFiles" icon="file-alt">Update ticket</DropdownItem>
                <DropdownItem
                    v-if="!isDebutSuccessful"
                    class="text-success"
                    title="Retry to debit?"
                    text="The debit process will restart"
                    confirm
                    @confirm="retryToDebit"
                    icon="redo"
                    >Retry to debit</DropdownItem
                >
                <DropdownItem @click="needRectification" icon="exclamation-circle">Need rectification</DropdownItem>
                <DropdownItem class="text-danger" @click="decline" icon="stop-circle">Decline</DropdownItem>
                <DropdownItem
                    class="text-success"
                    title="Confirm request?"
                    text="Amount will be refunded to bank account"
                    confirm
                    @confirm="confirm"
                    icon="check-circle"
                    >Confirm</DropdownItem
                >
                <DropdownItem
                    class="text-danger"
                    confirm
                    @confirm="cancel"
                    title="Cancel request?"
                    icon="times-circle"
                    text="The user will not be informed by e-mail of the cancellation of his request"
                    >Cancel</DropdownItem
                >
                <DropdownItem
                    v-if="false"
                    class="text-success"
                    title="Confirm request?"
                    text="Do you want to apply the status of this expense request to 'Debit successful' ?"
                    confirm
                    @confirm="setDebitSuccess"
                    icon="check-circle"
                    >Debit success</DropdownItem
                >
            </Dropdown>

            <!-- Update status -->
            <Modal
                v-if="isModalOpen('updateRequest')"
                @close="closeModal('updateRequest')"
                title="Update expense request"
            >
                <Update :id="request.id" @submitted="updateSubmitted"></Update>
            </Modal>

            <!-- Set status -->
            <Modal v-if="isModalOpen('setStatus')" @close="stopSetStatus" :title="'Set status to : ' + setStatus">
                <SetStatus :status="setStatus" :request="request" @submitted="statusSubmitted"></SetStatus>
            </Modal>

            <!-- Manage files -->
            <Modal v-if="isModalOpen('manageFiles')" @close="stopManageFiles" title="Manage files of request">
                <ManageFiles :id="request.id" @submitted="filesSubmitted" :initialFiles="sortedMedia"></ManageFiles>
            </Modal>
        </template>
    </Panel>
</template>

<script>
    import {
        Panel,
        Sheet,
        Row,
        ID,
        Alerts,
        Dropdown,
        DropdownItem,
        Modal,
        Alert,
        Tabs,
        Tab,
        Badge,
        Boolean,
    } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import { EXPENSE_REQUESTS } from '@tech_hexeko/shared/src/utils/constants.js'
    import SetStatus from './SetStatus'
    import ManageFiles from './ManageFiles'
    import Update from './Update'
    import SelectedPaymentMethod from '../layout/SelectedPaymentMethod/index'
    import Transactions from '@/components/PaymentTransactions'
    import Reason from '@/components/Reason'
    import History from '@/components/layout/History'
    import ExpenseRequests from '@/components/ExpenseRequests'
    import ExpenseRequestStatus from '@/components/ExpenseRequests/status'
    import Address from '@/components/Address'
    import PayoutRequests from '@/components/PayoutRequests'
    import PaymentIntents from '@/components/PaymentIntents'

    export default {
        name: 'ExpenseRequestPanel',
        components: {
            SelectedPaymentMethod,
            SetStatus,
            Panel,
            Sheet,
            Row,
            ID,
            Dropdown,
            DropdownItem,
            Address,
            ExpenseRequestStatus,
            Modal,
            ManageFiles,
            Alert,
            Tabs,
            Tab,
            Badge,
            Boolean,
            Update,
            Transactions,
            Reason,
            History,
            ExpenseRequests,
            PayoutRequests,
            PaymentIntents,
        },
        mixins: [Modals],
        props: {
            id: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                loading: true,
                request: null,
                setStatus: null,
                refreshAfterClose: false,
            }
        },
        computed: {
            formattedType() {
                if (!this.request.type) return false
                const matchedType = EXPENSE_REQUESTS.TYPES.find((type) => type.value === this.request.type)
                return matchedType ? matchedType.label : false
            },
            isDebutSuccessful() {
                return this.request.status.name === 'debit_successful'
            },
            hasMedia() {
                return this.request.media && this.request.media.length > 0
            },
            sortedMedia() {
                const order = ['media', 'second_media']
                const medias = this.hasMedia ? [...this.request.media] : []

                return medias.sort((mediaA, mediaB) => {
                    const orderA = order.indexOf(mediaA.name)
                    const orderB = order.indexOf(mediaB.name)
                    return orderA - orderB
                })
            },
        },
        methods: {
            fetchData() {
                this.loading = true
                // Load booking
                this.$api.expenseRequests
                    .show(this.id, {
                        includes: [
                            'user',
                            'partner.address',
                            'partner.suggested_by',
                            'media',
                            'selected_payment_methods',
                            'statuses',
                            'group',
                        ],
                    })
                    .then((request) => {
                        this.request = request
                        this.loading = false
                    })
            },
            openUser(user) {
                this.openPanel('userPanel', user.id)
            },
            openGroup(group) {
                this.openPanel('groupPanel', group.id)
            },
            openPartner(partner) {
                this.openPanel('partnerPanel', partner.id)
            },
            needRectification() {
                this.startSetStatus('needRectification')
            },
            decline() {
                this.startSetStatus('decline')
            },
            confirm() {
                this.$api.expenseRequests.confirm(this.id).then(() => {
                    Alerts.notificationSuccess('Expense confirmed')
                    this.fetchData()
                })
            },
            cancel() {
                this.$api.expenseRequests.cancel(this.id).then(() => {
                    Alerts.notificationSuccess('Request cancelled')
                    this.fetchData()
                })
            },
            retryToDebit() {
                this.$api.expenseRequests.retryToDebit(this.id).then(() => {
                    Alerts.notificationSuccess('The debit process has been restarted')
                    this.fetchData()
                })
            },
            startSetStatus(status) {
                this.setStatus = status
                this.openModal('setStatus')
            },
            stopSetStatus() {
                this.setStatus = null
                this.closeModal('setStatus')
            },
            statusSubmitted() {
                this.stopSetStatus()
                this.fetchData()
                this.expenseUpdated()
            },
            expenseUpdated() {
                this.refreshAfterClose = true
            },
            updateRequest() {
                this.openModal('updateRequest')
            },
            stopUpdateRequest() {
                this.closeModal('updateRequest')
            },
            manageFiles() {
                this.openModal('manageFiles')
            },
            stopManageFiles() {
                this.closeModal('manageFiles')
            },
            updateSubmitted() {
                this.fetchData()
                Alerts.notificationSuccess('Request updated')
                this.closeModal('updateRequest')
            },
            filesSubmitted() {
                this.loading = true
                setTimeout(() => {
                    this.fetchData()
                    Alerts.notificationSuccess('Files updated')
                    this.closeModal('manageFiles')
                }, 1000)
            },
            setDebitSuccess() {
                this.$api.expenseRequests.setDebitSuccessful(this.id).then(() => {
                    Alerts.notificationSuccess('Expense status set to successful')
                    this.fetchData()
                })
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
